







import { Component, Vue } from "vue-property-decorator";
import Hero from "@/sections/App/Hero/Hero.vue";
import DownloadApp from "@/sections/App/DownloadApp/DownloadApp.vue";

@Component({
  components: {
    Hero,
    DownloadApp,
  },
})
export default class App extends Vue {}
